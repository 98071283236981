// import { navigate } from 'gatsby';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

import { gsap } from "gsap";

import Point from '../assets/images/room/point.png'
import RoomImg1 from '../assets/images/room/360_001.jpg'
import RoomImg2 from '../assets/images/room/360_002.jpg'


const RoomFunc = (number) => {

  let jsWrapRoom1 = document.querySelector(".js-wrap-room1");
  let jsWrapRoom2 = document.querySelector(".js-wrap-room2");
  jsWrapRoom1.classList.add('act')
  jsWrapRoom2.classList.add('no_act')

  // サイズ------------------
  let wrap
  if (number === 'room1') {
    wrap = document.querySelector(".js-wrap-room1");
  } else if (number === 'room2') {
    wrap = document.querySelector(".js-wrap-room2");
  }
  let wrapWidth = wrap.clientWidth;
  let wrapHeight = wrap.clientHeight;

  // レンダラー------------------
  let canvas
  if (number === 'room1') {
    canvas = document.querySelector('.js-canvas-room1');
  } else if (number === 'room2') {
    canvas = document.querySelector('.js-canvas-room2');
  }
  const renderer = new THREE.WebGLRenderer({
    canvas: canvas
  });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(wrapWidth, wrapHeight);

  // シーン------------------
  const scene = new THREE.Scene();

  // カメラ------------------
  const camera = new THREE.PerspectiveCamera(75, wrapWidth / wrapHeight, 1, 1000);
  camera.position.set(0, 0, 1);

  // カメラコントローラーを作成
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.autoRotate = false; // 自動回転をONにする
  controls.autoRotateSpeed = 0.5; // 自動回転の速度
  controls.enableDamping = true; // 視点操作のイージングをONにする
  controls.dampingFactor = 0.1; // 視点操作のイージングの値
  controls.rotateSpeed = -0.15; // 視点変更の速さ
  controls.enableZoom = false; // ズーム禁止

  // 光源------------------
  // 環境光源
  const aLight = new THREE.AmbientLight(0xffffff, 1);
  scene.add(aLight);

  // テクスチャー------------------
  const loader = new THREE.TextureLoader();
  let texture;
  if (number === 'room1') {
    texture = loader.load(RoomImg1);
  } else if (number === 'room2') {
    texture = loader.load(RoomImg2);
  }

  // 物体------------------
  //球体
  const sphere = new THREE.Mesh(
    new THREE.IcosahedronGeometry(10, 15),
    new THREE.MeshPhongMaterial({
      map: texture
    }),
  );
  sphere.geometry.scale(-1, 1, 1); //表面を内側に向ける
  scene.add(sphere);


  // マウスとの交差を調べたいものは配列に格納する
  const meshList = [];

  let room1Point;
  let room2Point;
  // 画像を読み込む
  const PointTexture = new THREE.TextureLoader().load(Point,
    (tex) => { // 読み込み完了時
      // 縦横比を保って適当にリサイズ
      // const w = 0.1;
      // const h = tex.image.height / (tex.image.width / w);

      // 平面
      const geometry = new THREE.PlaneGeometry(1, 1, 1, 1);
      const material = new THREE.MeshPhongMaterial({ map: PointTexture, transparent: true });
      if (number === 'room1') {
        room1Point = new THREE.Mesh(geometry, material);
        room1Point.scale.set(1, 1, 1);
        room1Point.rotation.x = Math.PI / -2;
        room1Point.position.set(2, -5, 4);
        scene.add(room1Point);
        // 配列に保存
        meshList.push(room1Point);
      } else if (number === 'room2') {
        room2Point = new THREE.Mesh(geometry, material);
        room2Point.scale.set(1, 1, 1);
        room2Point.rotation.x = Math.PI / -2;
        room2Point.position.set(2, -1.3, -4.8);
        scene.add(room2Point);
        // 配列に保存
        meshList.push(room2Point);
      }
    });



  // マウス座標管理用のベクトルを作成
  const mouseMove = new THREE.Vector2();

  canvas.addEventListener('mousemove', handleMouseMove);

  // マウスを動かしたときのイベント
  function handleMouseMove(event) {
    const element = event.currentTarget;
    // canvas要素上のXY座標
    const x = event.clientX - element.offsetLeft;
    const y = event.clientY - element.offsetTop;
    // canvas要素の幅・高さ
    const w = element.offsetWidth;
    const h = element.offsetHeight;

    // -1〜+1の範囲で現在のマウス座標を登録する
    mouseMove.x = (x / w) * 2 - 1;
    mouseMove.y = -(y / h) * 2 + 1;
    raycasterMove()
  }


  // マウス座標管理用のベクトルを作成
  const mouseClick = new THREE.Vector2();

  canvas.addEventListener('click', handleMouseClick);

  // マウスをクリックしたときのイベント
  function handleMouseClick(event) {
    const element = event.currentTarget;
    // canvas要素上のXY座標
    const x = event.clientX - element.offsetLeft;
    const y = event.clientY - element.offsetTop;
    // canvas要素の幅・高さ
    const w = element.offsetWidth;
    const h = element.offsetHeight;

    // -1〜+1の範囲で現在のマウス座標を登録する
    mouseClick.x = (x / w) * 2 - 1;
    mouseClick.y = -(y / h) * 2 + 1;
    raycasterClick()
  }



  // レイキャストを作成
  const raycaster = new THREE.Raycaster();


  function raycasterMove() {
    // レイキャスト = マウス位置からまっすぐに伸びる光線ベクトルを生成
    raycaster.setFromCamera(mouseMove, camera);
    // その光線とぶつかったオブジェクトを得る
    const intersects = raycaster.intersectObjects(meshList);

    meshList.map((mesh) => {
      // 交差しているオブジェクトが1つ以上存在し、
      // 交差しているオブジェクトの1番目(最前面)のものだったら
      if (intersects.length > 0 && mesh === intersects[0].object) {
        console.log("OnMouse");
        gsap.to(mesh.scale, { x: 1.3, y: 1.3, z: 1, });
      } else {
        console.log("OffMouse");
        gsap.to(mesh.scale, { x: 1.0, y: 1.0, z: 1, });
      }
    });
  }


  function raycasterClick() {
    // レイキャスト = マウス位置からまっすぐに伸びる光線ベクトルを生成
    raycaster.setFromCamera(mouseMove, camera);
    // その光線とぶつかったオブジェクトを得る
    const intersects = raycaster.intersectObjects(meshList);

    meshList.map((mesh) => {
      // 交差しているオブジェクトが1つ以上存在し、
      // 交差しているオブジェクトの1番目(最前面)のものだったら
      if (intersects.length > 0 && mesh === intersects[0].object) {
        console.log("Click");
        if (number === 'room1') {
          jsWrapRoom1.classList.remove('act')
          jsWrapRoom2.classList.remove('no_act')
          jsWrapRoom1.classList.add('no_act')
          jsWrapRoom2.classList.add('act')
        } else if (number === 'room2') {
          jsWrapRoom1.classList.remove('no_act')
          jsWrapRoom2.classList.remove('act')
          jsWrapRoom1.classList.add('act')
          jsWrapRoom2.classList.add('no_act')
        }
      } else {
        console.log("noClick");
      }
    });
  }






  //リサイズ------------------
  const wrapResize = () => {
    // サイズ
    wrapWidth = wrap.clientWidth;
    wrapHeight = wrap.clientHeight;
    // レンダラー
    renderer.setSize(wrapWidth, wrapHeight);
  }

  //一定時間毎に処理------------------
  let tick;
  const switching = (e) => {
    if (e[0].isIntersecting) { //見えてる時
      tick = () => {
        wrapResize(); //リサイズ
        controls.update(); //カメラコントローラー
        renderer.render(scene, camera); //レンダリング
        requestAnimationFrame(tick); //繰り返し
      }
      requestAnimationFrame(tick);
    } else { //見えてない時
      tick = () => {
        cancelAnimationFrame(tick)
      }
    }
  }

  //見えているかどうか（Intersection Observer API）------------------

  //要素の監視
  const createObserver = () => {
    let observer;
    const options = { root: null, rootMargin: "0%", threshold: 0 };
    observer = new IntersectionObserver(switching, options); //コールバック関数とオプションを渡す
    observer.observe(wrap); //要素の監視を開始
  }

  //実行
  createObserver();

}
export default RoomFunc;