import * as React from "react"
import { useEffect } from 'react';
import { RoomFunc } from "@func/index";

const Room1 = () => {

  useEffect(() => {
    let room1 = "room1"
    RoomFunc(room1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="">
      {/* CONTENTS */}

      <div className="wrap js-wrap-a js-wrap-room1">
        <canvas className="canvas js-canvas js-canvas-room1"></canvas>
      </div>


      {/* CONTENTS */}
    </div>
  )
}

export default Room1
