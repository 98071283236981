import * as React from "react"
import { useEffect } from 'react';
import { RoomFunc } from "@func/index";

const Room2 = () => {

  useEffect(() => {
    let room2 = "room2"
    RoomFunc(room2);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="">
      {/* CONTENTS */}

      <div className="wrap js-wrap-a js-wrap-room2">
        <canvas className="canvas js-canvas js-canvas-room2"></canvas>
      </div>


      {/* CONTENTS */}
    </div>
  )
}

export default Room2
