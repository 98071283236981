import * as React from "react"
import { useEffect } from 'react';
import { Room1 } from "@components/index";
import { Room2 } from "@components/index";

const loadwrapStyle = {
  width: '100%',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 99999999,
  background: '#fff',
  opacity: 1,
}

const RoomPage = () => {

  useEffect(() => {

    let loadWrap = document.getElementById('loadWrap');
    const startFunc = () => {
      loadWrap.classList.add('no_act');
    }

    setTimeout(startFunc, 300)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pageRoom">
      {/* CONTENTS */}

      <>
        <div className="com-loadwrap" id="loadWrap" style={loadwrapStyle}></div>
        <div className="canvas-wrap" id="canvasWrap">
          <Room1 />
          <Room2 />
        </div>
      </>


      {/* CONTENTS */}
    </div>
  )
}

export default RoomPage
